import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [smallNavbar, setSmallNavbar] = useState(false);

  useEffect(() => {
    if (location.pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const handleLinkClick = () => {
    if (location.pathname !== "/") {
      window.scrollTo(0, 0);
    }
  };

  const getTextColor = (path) => {
    return location.pathname === path ? "text-primary-theme" : "text-secondary";
  };

  return (
    <>
      <nav className="navbar-custom py-3">
        <div className="container d-flex align-items-center gap-3 justify-content-between">
          <Link className="navbar-brand" to="/">
            Travel Gaps
          </Link>

          <div className="d-none-lg">
            <ul className="navbar-links d-flex align-items-center gap-3">
              <li>
                {location.pathname === "/" ? (
                  <a className="nav-link" href="/#home">
                    Home
                  </a>
                ) : (
                  <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    Home
                  </Link>
                )}
              </li>
              <li>
                <Link className="nav-link" to="service">
                  Our Services
                </Link>
              </li>
              <li>
                {location.pathname === "/" ? (
                  <a className="nav-link" href="/#benefits">
                    Benefits
                  </a>
                ) : (
                  <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    Benefits
                  </Link>
                )}
              </li>
              <li>
                {location.pathname === "/" ? (
                  <a className="nav-link" href="/#how_it_works">
                    How It Works
                  </a>
                ) : (
                  <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    How It Works
                  </Link>
                )}
              </li>
              <li>
                {location.pathname === "/" ? (
                  <a className="nav-link" href="/#testimonials">
                    Testimonials
                  </a>
                ) : (
                  <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    Testimonials
                  </Link>
                )}
              </li>
              <li>
                {location.pathname === "/" ? (
                  <a className="nav-link" href="/#faq">
                    FAQ
                  </a>
                ) : (
                  <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    FAQ
                  </Link>
                )}
              </li>
            </ul>
          </div>

          <div className="d-flex align-items-center gap-3">
            <div className="d-block-lg">
              <span
                className="btn btn-sm btn-outline-search"
                onClick={() => setSmallNavbar(!smallNavbar)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-list"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
                  />
                </svg>
              </span>
            </div>
            {location.pathname === "/" ? (
              <a className="btn btn-sm btn-outline-search" href="#contact_us">
                <div className="d-none-md">Schedule meeting</div>
                <div className="d-block-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chat-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15" />
                  </svg>
                </div>
              </a>
            ) : (
              <Link
                className="btn btn-sm btn-outline-search"
                to="/"
                onClick={handleLinkClick}
              >
                <div className="d-none-md">Schedule meeting</div>
                <div className="d-block-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-chat-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9 9 0 0 0 8 15" />
                  </svg>
                </div>
              </Link>
            )}
          </div>
        </div>
      </nav>

      {smallNavbar && (
        <div
          className="modal fade show"
          tabIndex="-1"
          style={{ display: "block", background: "rgba(0, 0, 0, 0.5)" }}
          aria-hidden="true"
        >
          <div className="modal-dialog my-0 me-0">
            <div className="modal-content" style={{ height: "100vh" }}>
              <div className="modal-header border-0 pb-0">
                <Link to="/" className="text-decoration-none">
                  <h5 class="text-primary-theme" id="exampleModalLabel">
                    Travel Gaps
                  </h5>
                </Link>
                <button
                  type="button"
                  class="btn-close"
                  onClick={() => setSmallNavbar(false)}
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body px-4">
                <ul className="d-flex flex-column gap-3 m-0 p-0">
                  <li className="mobile-item">
                    {location.pathname === "/" ? (
                      <a
                        href="/#home"
                        className={`mobile-link ${
                          location.hash === "#home" ? "active" : ""
                        } ${getTextColor("/")}`}
                        onClick={() => {
                          setSmallNavbar(false);
                        }}
                      >
                        Home
                      </a>
                    ) : (
                      <Link
                        to="/"
                        className={`mobile-link ${getTextColor("/")} ${
                          location.pathname === "/" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleLinkClick();
                          setSmallNavbar(false);
                        }}
                      >
                        Home
                      </Link>
                    )}
                  </li>
                  <li className="mobile-item">
                    <Link
                      to="service"
                      className={`mobile-link ${getTextColor("/service")}`}
                      onClick={() => {
                        setSmallNavbar(false);
                      }}
                    >
                      Our Services
                    </Link>
                  </li>
                  <li className="mobile-item">
                    {location.pathname === "/" ? (
                      <a
                        href="/#benefits"
                        className={`mobile-link ${
                          location.hash === "#benefits" ? "active" : ""
                        } ${getTextColor("/")}`}
                        onClick={() => {
                          setSmallNavbar(false);
                        }}
                      >
                        Benefits
                      </a>
                    ) : (
                      <Link
                        to="/"
                        className={`mobile-link ${getTextColor("/")} ${
                          location.pathname === "/" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleLinkClick();
                          setSmallNavbar(false);
                        }}
                      >
                        Benefits
                      </Link>
                    )}
                  </li>
                  <li className="mobile-item">
                    {location.pathname === "/" ? (
                      <a
                        href="/#how_it_works"
                        className={`mobile-link ${
                          location.hash === "#how_it_works" ? "active" : ""
                        } ${getTextColor("/")}`}
                        onClick={() => {
                          setSmallNavbar(false);
                        }}
                      >
                        How It Works
                      </a>
                    ) : (
                      <Link
                        to="/"
                        className={`mobile-link ${getTextColor("/")} ${
                          location.pathname === "/" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleLinkClick();
                          setSmallNavbar(false);
                        }}
                      >
                        How It Works
                      </Link>
                    )}
                  </li>
                  <li className="mobile-item">
                    {location.pathname === "/" ? (
                      <a
                        href="/#testimonials"
                        className={`mobile-link ${
                          location.hash === "#testimonials" ? "active" : ""
                        } ${getTextColor("/")}`}
                        onClick={() => {
                          setSmallNavbar(false);
                        }}
                      >
                        Testimonials
                      </a>
                    ) : (
                      <Link
                        to="/"
                        className={`mobile-link ${getTextColor("/")} ${
                          location.pathname === "/" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleLinkClick();
                          setSmallNavbar(false);
                        }}
                      >
                        Testimonials
                      </Link>
                    )}
                  </li>
                  <li className="mobile-item">
                    {location.pathname === "/" ? (
                      <a
                        href="/#faq"
                        className={`mobile-link ${
                          location.hash === "#faq" ? "active" : ""
                        } ${getTextColor("/")}`}
                        onClick={() => {
                          setSmallNavbar(false);
                        }}
                      >
                        FAQ
                      </a>
                    ) : (
                      <Link
                        to="/"
                        className={`mobile-link ${getTextColor("/")} ${
                          location.pathname === "/" ? "active" : ""
                        }`}
                        onClick={() => {
                          handleLinkClick();
                          setSmallNavbar(false);
                        }}
                      >
                        FAQ
                      </Link>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
